import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { getCookieValue } from 'app/utils/cookie';
import { genericRequest } from 'app/api';
import { login } from 'app/modules/Auth/_redux/AuthActions';
import { DIALOG_COOKIE, SESSION_COOKIE } from 'app/utils/constants';

const AuthInit = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = intl;

  const onUnauthorized = () => {
    window.location.href = `${process.env.REACT_APP_BIGBUY_URL}${locale}/tiendab2b.html`;
  };

  useEffect(() => {
    const cookie = getCookieValue(SESSION_COOKIE);
    if (!cookie) {
      onUnauthorized();
    }
  });

  useEffect(() => {
    const authLogin = async () => {
      try {
        const user = await genericRequest({ url: 'customers/me' });

        dispatch(login(user.data));
        const dialogCookie = Cookies.get(DIALOG_COOKIE);
        if (!dialogCookie) {
          Cookies.set(DIALOG_COOKIE, 'default', { expires: 4 });
        }
      } catch (e) {
        onUnauthorized();
      }
    };

    authLogin();

    return () => authLogin();
  });

  return children;
};

export default AuthInit;
